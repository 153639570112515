import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';
import paginationComponent from '@/components/Pagination'

export default {
    name: 'Questions',
    data() {
        return {
            questions: [],
            totalQuestions: 0
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout,
        paginationComponent
    },
    mounted() {
        this.getQuestions()
    },
    methods: {
        pageChange: function (nr) {
            this.getQuestions(nr);
        },
        getQuestions(pageParamter = 1, limitParamter = this.paginationPerPage()) {
            const self = this;
            const withCounts = '?withCount=options';
            const limit = '&limit=' + limitParamter;
            const page = '&page=' + pageParamter;
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/questions' + withCounts + limit + page,
                method: 'GET'
            }).then(response => {
                self.questions = response.data.rows;
                self.totalQuestions = response.data.total_data;
            })
        }
    }
}
